
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import { JobStatus } from '@/enums/JobStatus';
import VItemSelect from '@/components/shared/table/VItemSelect.vue';
import sortByDate from '@/components/eposJobs/sortByDate';

interface Selection {
  from?: string;
  to?: string;
  venue?: string[];
  status?: any[];
  requestId?: string;
}

@Component({
  components: { VItemSelect, VRangePicker },
})
export default class EposJobsFilter extends Vue {
  @Prop({ type: Array, required: true }) public venues!: Venue[];

  public venue: string[] = [];
  public range = {
    from: '',
    to: '',
  };
  public status: string[] = [];
  public requestId: any = '';
  public sourceTypeByDate = sortByDate;

  get selection() {
    const selection: Selection = {};
    if (this.venue) {
      selection.venue = this.venue;
    }
    if (this.range && this.range.from && this.range.to) {
      selection.from = this.range.from;
      selection.to = this.range.to;
    }
    if (this.status) {
      selection.status = this.status;
    }

    if (this.requestId) {
      selection.requestId = this.requestId;
    }

    return selection;
  }

  public handleSortByCreatedAt(event: string) {
    this.$emit('sortByCreatedAt', { sortType: event });
  }

  @Watch('selection')
  @Emit('change')
  public onSelectionChange() {
    return this.selection;
  }

  get statuses() {
    return [
      { text: this.$t('eposJobs.form.success'), value: JobStatus.SUCCESS },
      { text: this.$t('eposJobs.form.failed'), value: JobStatus.FAILED },
      { text: this.$t('eposJobs.form.partiallyFailed'), value: JobStatus.PARTIALLY_FAILED },
      { text: this.$t('eposJobs.form.pending'), value: JobStatus.PENDING },
      { text: this.$t('eposJobs.form.started'), value: JobStatus.STARTED },
    ];
  }
}
