
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import VItemSelect from '@/components/shared/table/VItemSelect.vue';
import sortByDate from '@/components/eposJobs/sortByDate';
import { SanifairRedemptionType } from '../../enums/SanifairRedemptionType';

interface Selection {
  from?: string;
  to?: string;
  venue?: string[];
  status?: any[];
  search?: string;
}

@Component({
  components: { VItemSelect, VRangePicker },
})
export default class SanifairFilter extends Vue {
  @Prop({ type: Array, required: true }) public venues!: Venue[];

  public venue: string[] = [];
  public range = {
    from: '',
    to: '',
  };
  public status: string[] = [];
  public search: any = '';
  public sourceTypeByDate = sortByDate;

  get selection() {
    const selection: Selection = {};
    if (this.venue) {
      selection.venue = this.venue;
    }
    if (this.range && this.range.from && this.range.to) {
      selection.from = this.range.from;
      selection.to = this.range.to;
    }
    if (this.status) {
      selection.status = this.status;
    }

    if (this.search) {
      selection.search = this.search;
    }

    return selection;
  }

  public handleSortByCreatedAt(event: string) {
    this.$emit('sortByCreatedAt', { sortType: event });
  }

  @Watch('selection')
  @Emit('change')
  public onSelectionChange() {
    return this.selection;
  }

  get statuses() {
    return [
      { text: this.$t('sanifairLogs.form.reserve'), value: SanifairRedemptionType.RESERVE },
      { text: this.$t('sanifairLogs.form.commit'), value: SanifairRedemptionType.COMMIT },
      { text: this.$t('sanifairLogs.form.uncommit'), value: SanifairRedemptionType.UNCOMMIT },
      { text: this.$t('sanifairLogs.form.redeem'), value: SanifairRedemptionType.REDEEM },
    ];
  }
}
