
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import { OrderFlavor } from '@/enums/OrderFlovor';
import VRangePickerOneField from '../shared/form/VDateRangePickerOneField.vue';

interface Selection {
  from?: string;
  to?: string;
  venue?: string;
  flavor?: OrderFlavor;
}

@Component({
  components: { VRangePicker, VRangePickerOneField },
})
export default class ReceiptFilter extends Vue {
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Boolean, default: false }) public disableFlavor!: boolean;

  public venue: string = '';
  public range = {
    from: '',
    to: '',
  };
  public flavor: OrderFlavor = process.env.NODE_ENV === 'production' ? OrderFlavor.PROD_RELEASE : OrderFlavor.DEV_DEBUG;

  get selection() {
    const selection: Selection = {};
    if (this.venue) {
      selection.venue = this.venue;
    }
    if (this.range && this.range.from && this.range.to) {
      selection.from = this.range.from;
      selection.to = this.range.to;
    }
    if (this.flavor && !this.disableFlavor) {
      selection.flavor = this.flavor;
    }

    return selection;
  }

  onChangeRange(event: { from: string; to: string }) {
    this.range.from = event.from;
    this.range.to = event.to;
  }

  @Watch('selection')
  @Emit('change')
  public onSelectionChange() {
    return this.selection;
  }

  get flavors(): string[] {
    return Object.values(OrderFlavor);
  }
}
