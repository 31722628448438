export default [
  {
    text: 'receipt.table.tableNumber',
    value: 'tableNumber',
  },
  {
    text: 'receipt.table.code',
    value: 'orders',
  },
  {
    text: 'receipt.table.at',
    value: 'createdAt',
  },
  {
    text: 'receipt.table.sum',
    value: 'sum',
  },
  {
    text: 'Total Pos Checkout',
    value: 'totalPosCheckoutReceipt',
  },
  {
    text: 'receipt.table.merchantReceipt',
    value: 'posMerchantReceipt',
  },
  {
    text: 'receipt.table.customerReceipt',
    value: 'posCustomerReceipt',
  },
  {
    text: 'receipt.table.posCheckoutReceipt',
    value: 'posCheckoutReceipt',
  },
  {
    text: '',
    value: 'actions',
  },
  { text: '', value: 'data-table-expand' },
];
