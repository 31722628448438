export default [
  {
    text: 'eposJobs.table.itemId',
    value: 'itemId',
  },
  {
    text: 'eposJobs.table.statusCode',
    value: 'statusCode',
  },
  {
    text: 'eposJobs.table.taxIdInside',
    value: 'taxIdInside',
  },
  {
    text: 'eposJobs.table.taxIdTakeaway',
    value: 'taxIdTakeaway',
  },
  { text: '', value: 'data-table-expand' },
];
