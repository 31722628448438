export default [
  {
    text: 'eposJobs.table.articleName',
    value: 'articleName',
  },
  {
    text: 'eposJobs.table.ID',
    value: '_id',
  },
  {
    text: 'eposJobs.table.articleType',
    value: 'articleType',
  },
  {
    text: 'eposJobs.table.itemId',
    value: 'itemId',
  },
  { text: '', value: 'data-table-expand' },
];
