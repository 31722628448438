
import VWrapper from '@/components/shared/VWrapper.vue';
import Filter from '@/interfaces/api/Filter';
import { Page } from '@/interfaces/api/Page';
import { SanifairLog } from '@/interfaces/models/SanifairLog';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SanifairLogsTable from '../../components/sanifairLogs/SanifairLogsTable.vue';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import Notification from '@/mixins/Notification';
import SanifairFilter from '../../components/sanifairLogs/SanifairFilter.vue';

const app = namespace('app');
const sanifair = namespace('sanifairLogs');

@Component({
  components: { VWrapper, SanifairLogsTable, SanifairFilter },
})
export default class SanifairLogs extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];
  @sanifair.State('items') public sanifarLogs!: SanifairLog[];
  @sanifair.State('filter') public filter!: Filter;
  @sanifair.State((state) => state.pagination.total) public total!: number;
  @sanifair.Action('fetch') public getSanifairLogs!: any;
  @sanifair.Action('setFilter') public setFilter!: (filter: Filter) => void;

  public limit: number = 50;

  public serializedItems() {
    return this.sanifarLogs;
  }

  public async onSanifairFilterChange(filter: Filter) {
    this.$startLoading('sanifarLogs.index');
    this.setFilter({ ...filter });
    await this.getSanifairLogs({ page: 1, limit: this.limit });
    this.$stopLoading('sanifarLogs.index');
  }

  public async onPageChange(page: Page) {
    this.$startLoading('sanifarLogs.index');
    await this.getSanifairLogs(page);
    this.$stopLoading('sanifarLogs.index');
  }
}
