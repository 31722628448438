
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './requestHeaders';
import { EposRequest } from '@/interfaces/models/EposRequest';

@Component
export default class RequestsTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: EposRequest[];
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;

  public expanded: string[] = [];

  get tableHeaders() {
    return headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }
}
