
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './eposJobsHeaders';
import { Page } from '@/interfaces/api/Page';
import { EposChangeItem, EposJob } from '@/interfaces/models/EposJob';
import ChangesTable from '@/components/eposJobs/ChangesTable.vue';
import RequestsTable from '@/components/eposJobs/RequestsTable.vue';
import ErrorsTable from '@/components/eposJobs/ErrorsTable.vue';
import { EposJobsApiService } from '@/api/http/EposJobsApiService';
import { AxiosResponse } from 'axios';

@Component({
  components: { ErrorsTable, RequestsTable, ChangesTable },
})
export default class EposJobsTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: EposJob[];
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Number, required: true }) public limit!: number;

  public changeItems: EposChangeItem[] = [];
  public expanded: string[] = [];
  public page: number = 1;
  public rowsPerPage: number = 10;

  get pageCount() {
    if (this.pagination) {
      return Math.ceil(this.total / this.rowsPerPage);
    }

    return 0;
  }

  get tableHeaders() {
    return headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  public fetchEpos(id: string) {
    this.$startLoading('eposJobs.index');
    const api: EposJobsApiService = new EposJobsApiService();
    api
      .show({ id })
      .then((res: AxiosResponse<EposJob>) => {
        this.changeItems = res.data.changes;
      })
      .finally(() => {
        this.$stopLoading('eposJobs.index');
      });
  }

  @Emit('page')
  @Watch('page')
  public onPageChange(): Page {
    return { page: this.page, limit: this.limit };
  }

  @Watch('expanded')
  public onExpanded(items: EposJob[]): any {
    if (!items.length) return;
    this.changeItems = [];
    this.fetchEpos(items[0]._id);
  }
}
