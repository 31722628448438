
import { Component } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import ReceiptFilter from '../../components/receipt/ReceiptFilter.vue';
import { Venue } from '@/interfaces/models/Venue';
import { EposJob } from '@/interfaces/models/EposJob';
import { namespace } from 'vuex-class';
import ReceiptTable from '@/components/receipt/ReceiptTable.vue';
import Filter from '@/interfaces/api/Filter';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import EndOfDayReceiptTable from '@/components/receipt/EndOfDayReceiptTable.vue';
import EposJobsFilter from '@/components/eposJobs/EposJobsFilter.vue';
import EposJobsTable from '@/components/eposJobs/EposJobsTable.vue';
import { Page } from '@/interfaces/api/Page';

const app = namespace('app');
const eposJobs = namespace('eposJobs');

@Component({
  components: { EposJobsTable, EposJobsFilter, EndOfDayReceiptTable, ReceiptTable, ReceiptFilter, VWrapper },
})
export default class EposJobs extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];

  @eposJobs.State('items') public jobs!: EposJob[];
  @eposJobs.State('filter') public filter!: Filter;
  @eposJobs.Action('fetch') public getEposJobs!: any;
  @eposJobs.Action('setFilter') public setEposJobsFilter!: (filter: Filter) => void;
  @eposJobs.Action('sortByCreatedAt') public sortByCreatedAt!: (filter: Filter) => void;
  @eposJobs.State((state) => state.pagination.total) public total!: number;

  public limit: number = 10;

  public serializedItems() {
    return this.jobs;
  }

  public async onEposJobsFilterChange(filter: Filter) {
    this.$startLoading('jobs.index');
    this.setEposJobsFilter({ ...filter });
    await this.getEposJobs({ page: 1, limit: this.limit });
    this.$stopLoading('jobs.index');
  }

  public onSortByCreatedAt(filter: Filter) {
    this.sortByCreatedAt(filter);
  }

  public async onPageChange(page: Page) {
    this.$startLoading('jobs.index');
    await this.getEposJobs(page);
    this.$stopLoading('jobs.index');
  }
}
