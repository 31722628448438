export default [
  {
    text: 'sanifairLogs.table.sanifairActionType',
    value: 'sanifairActionType',
  },
  {
    text: 'sanifairLogs.table.createdAt',
    value: 'createdAt',
  },
  {
    text: 'sanifairLogs.table.order',
    value: 'order',
  },
  {
    text: 'sanifairLogs.table.responseTimeSanifair',
    value: 'responseTimeSanifair',
  },
  {
    text: 'sanifairLogs.table.responseTimeSmoothr',
    value: 'responseTimeSmoothr',
  },
  {
    text: 'sanifairLogs.table.responseTimeTotal',
    value: 'responseTimeTotal',
  },
  {
    text: 'sanifairLogs.table.sanifairRequestReceivedAt',
    value: 'sanifairRequestReceivedAt',
  },
  {
    text: 'sanifairLogs.table.sanifairResponseReceivedAt',
    value: 'sanifairResponseReceivedAt',
  },
  {
    text: 'sanifairLogs.table.smoothrRequestReceivedAt',
    value: 'smoothrRequestReceivedAt',
  },
  {
    text: 'sanifairLogs.table.smoothrResponseReceivedAt',
    value: 'smoothrResponseReceivedAt',
  },
  { text: '', value: 'data-table-expand' },
];
