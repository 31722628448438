
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Page } from '@/interfaces/api/Page';
import { SanifairLog } from '@/interfaces/models/SanifairLog';
import moment from 'moment';

@Component({
  components: {},
})
export default class SanifairLogsTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: SanifairLog[];
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Number, required: true }) public limit!: number;

  public expanded: string[] = [];
  public page: number = 1;
  public rowsPerPage: number = 50;

  get pageCount() {
    if (this.pagination) {
      return Math.ceil(this.total / this.rowsPerPage);
    }

    return 0;
  }

  get tableHeaders() {
    return headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  @Emit('page')
  @Watch('page')
  public onPageChange(): Page {
    return { page: this.page, limit: this.limit };
  }
}
