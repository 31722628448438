
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import PromoCodeTable from '@/components/promo/PromoCodeTable.vue';
import CustomerGroup from '@/mixins/CustomerGroup';
import { Venue } from '@/interfaces/models/Venue';
import AutomatedReportTable from '@/components/automatedReport/AutomatedReportTable.vue';
import { Job } from '@/interfaces/models/Job';

const app = namespace('app');
const venue = namespace('venue');
const automaticReport = namespace('automaticReport');

@Component({
  components: { AutomatedReportTable, PromoCodeTable, VEmptyState, TableTable, VWrapper },
})
export default class AutomatedReport extends mixins(Notification, CustomerGroup) {
  @app.State('venues') public venues!: Venue[];
  @app.Action('fetchVenues') public getVenues!: any;
  @automaticReport.State('filter') public filter!: any;
  @automaticReport.State('items') public jobs!: Job[];

  @automaticReport.Action('fetch') public getAutomatedReports!: any;
  @automaticReport.Action('setFilter') public setFilter!: any;
  @automaticReport.Action('fetchAutomaticReportByVenue') public getAutomatedReportsByVenue!: any;
  @automaticReport.Action('fetchAutomaticReportByGroup') public getAutomatedReportsByGroup!: any;

  public serializedItems() {
    return this.jobs.map((job: Job) => {
      return { ...job, ...job.params };
    });
  }

  public async onSearchByGroup(queries: any) {
    this.setFilter({ customerGroup: queries });
    await this.getAutomatedReportsByGroup();
  }

  public async onSearchByVenue(queries: any) {
    this.setFilter({ venueId: queries });
    await this.getAutomatedReportsByVenue();
  }
}
