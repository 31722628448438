export default [
  {
    text: 'eposJobs.form.oldestToNewest',
    value: 'asc',
  },
  {
    text: 'eposJobs.form.newestToOldest',
    value: 'desc',
  },
];
