
import { Component } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import ReceiptFilter from '../../components/receipt/ReceiptFilter.vue';
import { Venue } from '@/interfaces/models/Venue';
import { Receipt as ReceiptModel } from '@/interfaces/models/Receipt';
import { namespace } from 'vuex-class';
import ReceiptTable from '@/components/receipt/ReceiptTable.vue';
import Filter from '@/interfaces/api/Filter';
import { ReceiptApiService } from '@/api/http/ReceiptApiService';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import EndOfDayReceiptTable from '@/components/receipt/EndOfDayReceiptTable.vue';
import { Page } from '@/interfaces/api/Page';

const app = namespace('app');
const receipt = namespace('receipt');

@Component({
  components: { EndOfDayReceiptTable, ReceiptTable, ReceiptFilter, VWrapper },
})
export default class Receipt extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];

  @receipt.State('items') public receipts!: Receipt[];
  @receipt.State('endOfDayItems') public endOfDayReceipts!: Receipt[];
  @receipt.Action('fetch') public getReceipts!: any;
  @receipt.Action('fetchEndOfDay') public getEndOfDay!: any;
  @receipt.Action('setFilter') public setReceiptFilter!: (filter: Filter) => void;
  @receipt.Action('setEndOfDayFilter') public setEndOfDayFilter!: (filter: Filter) => void;
  @receipt.State((state) => state.pagination.total) public total!: number;

  public serializedItems() {
    return this.receipts.map((item: any) => {
      item.totalPosCheckoutReceipt = null;
      if (item.posCheckoutReceipt) {
        const input = item.posCheckoutReceipt;
        const el: any = document.createElement('div');

        el.innerHTML = input;

        const right: any = el.querySelectorAll('right');
        // tslint:disable-next-line
        for (let i = 0; i < right.length; i++) {
          if (
            right[i].previousSibling &&
            right[i].previousSibling.nodeValue &&
            right[i].previousSibling.nodeValue.trim() === 'Total'
          ) {
            const value = right[i].innerText;
            item.totalPosCheckoutReceipt = parseFloat(value.replace('EUR', '').trim().replace(',', '.'));
            break;
          }
        }
      }
      return item;
    });
  }

  public async onReceiptFilterChange(filter: Filter) {
    if (!filter.venue) {
      return;
    }
    this.$startLoading('receipt.index');
    this.setReceiptFilter({ ...filter });
    await this.getReceipts();
    this.$stopLoading('receipt.index');
  }

  public async onEndOfDayReceiptChange(filter: Filter) {
    if (!filter.venue) {
      return;
    }
    this.$startLoading('receipt.endOfDay');
    this.setEndOfDayFilter({ ...filter });
    await this.getEndOfDay();
    this.$stopLoading('receipt.endOfDay');
  }

  public exportCustomer(item: ReceiptModel) {
    if (item.posCustomerReceipt && item.posCustomerReceipt.length > 0) {
      const api: ReceiptApiService = new ReceiptApiService();
      this.$startLoading('receipt.export');
      return api
        .exportCustomer({ id: item._id })
        .then((res: AxiosResponse<Blob>) => {
          toUrl(res.data, `customer-receipt-${moment().format('DD-MM-YYYY')}.pdf`);
        })
        .finally(() => {
          this.$stopLoading('receipt.export');
        });
    }

    this.notifyError('receipt.notification.noReceipt');
  }

  public exportMerchant(item: ReceiptModel) {
    if (item.posMerchantReceipt && item.posMerchantReceipt.length > 0) {
      const api: ReceiptApiService = new ReceiptApiService();
      this.$startLoading('receipt.export');
      return api
        .exportMerchant({ id: item._id })
        .then((res: AxiosResponse<Blob>) => {
          toUrl(res.data, `merchant-receipt-${moment().format('DD-MM-YYYY')}.pdf`);
        })
        .finally(() => {
          this.$stopLoading('receipt.export');
        });
    }

    this.notifyError('receipt.notification.noReceipt');
  }

  public exportCombined(item: ReceiptModel) {
    if (
      item.posMerchantReceipt &&
      item.posMerchantReceipt.length > 0 &&
      item.posCustomerReceipt &&
      item.posCustomerReceipt.length > 0
    ) {
      const api: ReceiptApiService = new ReceiptApiService();
      this.$startLoading('receipt.export');
      return api
        .exportCombined({ id: item._id })
        .then((res: AxiosResponse<Blob>) => {
          toUrl(res.data, `combined-combined-${moment().format('DD-MM-YYYY')}.pdf`);
        })
        .finally(() => {
          this.$stopLoading('receipt.export');
        });
    }

    this.notifyError('receipt.notification.noReceipt');
  }

  public exportFull(item: ReceiptModel) {
    const api: ReceiptApiService = new ReceiptApiService();
    this.$startLoading('receipt.export');
    return api
      .exportFull({ id: item._id })
      .then((res: AxiosResponse<Blob>) => {
        toUrl(res.data, `full-receipt-${moment().format('DD-MM-YYYY')}.pdf`);
      })
      .finally(() => {
        this.$stopLoading('receipt.export');
      });
  }
}
