
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import { Venue } from '@/interfaces/models/Venue';
import headers from './headers';
import VColumnSelect from '@/components/shared/table/VColumnSelect.vue';
import VItemSelect from '@/components/shared/table/VItemSelect.vue';
import { Job } from '@/interfaces/models/Job';
import { CustomerGroup } from '@/enums/CustomerGroup';

@Component({
  components: { VItemSelect, VColumnSelect, VTable },
})
export default class AutomatedReportTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Job[];
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Array, required: true }) public customerGroups!: CustomerGroup[];

  public headers: ColumnHeader[] = headers;
  public venue: string | null = '';
  public customerGroup: string | null = '';

  public venueNames(venues: string[]) {
    if (!venues || venues.length === 0) {
      return '-';
    }
    const names: Array<string | undefined> = this.venues.map((v: Venue) => {
      if (venues && venues!.indexOf(v._id) !== -1) {
        return v.name;
      }
    });

    return names.filter((n: string | undefined) => n !== undefined).join(', ');
  }

  @Watch('venue')
  @Emit('searchByVenue')
  public onVenueChange() {
    return this.venue ? this.venue : null;
  }

  @Watch('customerGroup')
  @Emit('searchByGroup')
  public onCustomerGroupChange() {
    return this.customerGroup ? this.customerGroup : null;
  }
}
