export default [
  {
    text: 'eposJobs.table.requestId',
    value: 'requestId',
  },
  {
    text: 'eposJobs.table.sapNumber',
    value: 'sapNumber',
  },
  {
    text: 'eposJobs.table.status',
    value: 'status',
  },
  {
    text: 'eposJobs.table.statusMessage',
    value: 'statusMessage',
  },
  {
    text: 'eposJobs.table.createdAt',
    value: 'createdAt',
  },
  {
    text: 'eposJobs.table.finalizedAt',
    value: 'finalizedAt',
  },
  { text: '', value: 'data-table-expand' },
];
