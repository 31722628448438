export default [
  {
    text: 'receipt.table.at',
    value: 'createdAt',
  },
  {
    text: 'receipt.table.endOfDay',
    value: 'endOfDay',
  },
  { text: '', value: 'data-table-expand' },
];
