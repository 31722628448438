export default [
  {
    text: 'automatedReports.form.type',
    value: 'type',
  },
  {
    text: 'automatedReports.form.customerGroup',
    value: 'customerGroup',
  },
  {
    text: 'automatedReports.form.flavor',
    value: 'flavor',
  },
  {
    text: 'automatedReports.table.frequency',
    value: 'frequency',
  },
  {
    text: 'automatedReports.table.lastExecuted',
    value: 'lastExecuted',
    type: 'slot',
  },
  /*{
        text: 'automatedReports.form.venues',
        value: 'venues',
        type: 'slot',
    },*/
];
